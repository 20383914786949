button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Navbar */
.logo {
  max-width: 246px;
  justify-content: center;
  align-content: center;
}

.navBarContainer {
  background-Color: #2B2452;
  color: white;
  font-Family: "Timeless-Normal";
  font-Size: 20px
}

.navbarContainerPadding {
  padding-right: 2px !important;
  padding-left: 5px !important;
}

.navBarStyle {
  background-Color: #2B2452;
  color: white;
  font-Family: "Timeless-Normal";
}

.navigation-bar {
  justify-content: center;
}

.nav-bar-link-position {
  top: 10px;
  position: relative;
}

/* twitch Drop Logo And Header */
.twitchDropLogo {
  width: 250px;
}

.winter-fever-drops {
  width: 100%;
  border-image: url("/public/images/cardHeader9Slice.png") 21 50 14 50 fill / 21px 50px 14px 50px / 0px stretch;
}

.countDownRowHeader {
  font-size: 24px;
  font-family: "Timeless-Normal";
  background-color: #000000;
  max-width: 170px !important;
}

.countDownRowNumber {
  background: url('../public/images/thumbnail-image-alpha-small.png');
  background-Repeat: no-repeat;
  background-Position: center;
  line-height: 80px;
  font-Size: 50px;
  font-Family: "Timeless-Normal";
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto;
  height: auto;
  background-Size: 100% 100%;
  max-width: 80px !important;
  max-height: 80px !important;
}

.yellowTextColor {
  color: #ffe1ad;
}

.headerText {
  font-Family: "Timeless-Normal";
  font-Size: 30px;
  color: white;
  text-shadow: 1px 2px 4px black;
  font-weight: 700;
}

.headerTitle {
  padding: 0 20px;
}

.leftDiamond {
  width: 29px;
  height: 21px;
}

.rightDiamond {
  width: 29px;
  height: 21px;
}

.beta-warning{
  color:white;
  font-Family: "Timeless-Normal";
  font-size: 20px;
}
.beta-warning .discord-link {
    color:white;
    text-decoration: none;
    background-color: transparent;
    text-transform: uppercase;
}
.beta-warning .discord-link:hover {
  text-decoration: underline;
}

/* Twitch drop Countdown */
.countDownColumn {
  max-Width: 169px;
  min-Width: 125px;
}

.countAndDrops {
  padding-right: unset !important;
  padding-left: unset !important;
}

/* Twitch Drops Cards*/

.dropCard {
  border-Radius: 0.5rem;
  box-Shadow: 0.5rem 0.5rem 1rem #2B2452;
  font-Family: "Timeless-Normal";
  font-size: 20px;
  height: 100%;
}

.dropCardBackground {
  background-color: rgba(255, 255, 255, 0.85) !important;
  height: 100%;
}

.dropCardHeader {
  border-image: url("/public/images/cardHeader9Slice.png") 21 50 14 50 fill / 21px 50px 14px 50px / 0px stretch;  
}

/* Footer */
.footer {
  margin-top: auto;
  background: #2B2452;
}

.imageContainer {
  display: inline-block;
  position: relative;
  width: 60px !important;
  height: 60px;
  margin: 0 25px 0 25px;
}

.imageHidden {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity;
  width: 60px;
  height: 60px;
  opacity: 0;
}

.imageDefault {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity;
  width: 60px;
  height: 60px;
  opacity: 1;
}

.imageHidden:hover {
  opacity: 1;
  display: inline-block;
}

.imageDefault:hover {
  opacity: 0;
  display: none;
}

/* Account Stuff */
.normalText {
  font-Family: "Timeless-Normal";
  color: white;
  font-weight: 700;
}

.linkAccountContent {
  background-color: rgb(43, 37, 37, 0.75);
  color: white;
  width: 100%;
}

.buttonBackground {
  background-image: url("/public/images/linkButton_normal.svg");
  background-position: center;
  color: white;
  width: 258px;
  height: 51px;
}

.buttonBackgroundSmall {
  background-image: url("/public/images/linkButtonSmall_normal.png");
  background-position: center;
  color: white;
  width: 150px;
  height: 51px;
}

.img {
  margin-left: 12px;
  width: 35px;
  height: 35px;
}

span.account {
  padding-left: 10px;
  padding-right: 20px;
  font-size: 26px;
  font-Family: "Timeless-Normal";
}

.span {
  padding-left: 10px;
}

.buttonBackgroundSmall:hover {
  background-image: url("/public/images/linkButtonSmall_highlight.png");
  width: 150px;
}

.buttonBackgroundSmall:disabled {
  background-image: url("/public/images/linkButtonSmall_disabled.png");
  color: grey;
  opacity: 0.4;
  height: 46px;
  width: 150px;
}

.buttonBackground:hover {
  background-image: url("/public/images/linkButton_highlight.svg");
}

.buttonBackground:disabled {
  background-image: url("/public/images/linkButton_disabled.svg");
  color: grey;
  opacity: 0.4;
  height: 46px;
}

/* Misc */
.streamButton {
  background: url('../public/images/coachStock.png');
  background-Size: 100% 100% !important;
  width: 100%;
  height: 75px
}

.twitchDropsContent {
  padding-right: unset !important;
  padding-left: unset !important;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
  top: auto !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 0.75) !important;
  border-radius: 10px;
  box-Shadow: 0.35rem 0.35rem 0.75rem #2B2452;
  filter: none !important;
}

.carousel-indicators [data-bs-target] {
  border-bottom: 0px !important;
}

.activeLink {
  background-image: linear-gradient(90deg, transparent, hsla(0, 0%, 83.5%, .2) 20%, hsla(0, 0%, 83.5%, .2) 80%, transparent);
  color: white;
}

.mainContent {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-content: center;
}

.twitchDropSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  background-image: url("/public/images/websitebg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.backgroundPast {
  background-image: url("/public/images/wood-chopping.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.backgroundFuture {
  background-image: url("/public/images/hunting.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.backgroundFaq {
  background-image: url("/public/images/camp-fire.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.backgroundAccount {
  background-image: url("/public//images/background_store.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.backgroundTwitchCountdown {
  background-color: rgb(43, 37, 37, 0.75);
  color: white;
  font-size: 15px;
  font-family: "Timeless-Normal";
  justify-content: center;
  margin-bottom: 10px !important;
}

.inactiveLink {
  color: #746D6D;
}

/* .background-twitch-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-left: 20px;
  margin-right: 20px;
}
*/

/* Media Queries */
@media only screen and (max-width: 370px) {

  /* Does this style do anything? */
  .diamond.ms-3 {
    padding: 0em !important;
    margin-left: 0.5em !important;
  }

  .nav-bar-center {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 430px) {
  .normalText {
    font-size: 23px;
  }
}

/* Does this query do anything?  */
@media only screen and (max-width: 450px) {
  .diamond.me-3 {
    padding: 0em !important;
    margin-right: 0em !important;
  }
}


@media only screen and (max-width: 576px) {
  .nav-item {
    margin: auto;
  }
}


@media only screen and (min-width: 576px) {
  .logo {
    max-width: 320px;
  }

  .countAndDrops {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}

@media only screen and (min-width: 768px) {
  .headerText {
    font-Size: 42px !important;
  }

  .twitchDropLogo {
    /* 768px is about 22% smaller then 992
      So this width is about 22% smaller then 418px of the native image
    */
    width: 326px;
  }
}

@media only screen and (min-width: 992px) {
  .headerText {
    font-Size: 48px !important;
  }

  .twitchDropLogo {
    /* native resolution for the image */
    width: 418px;
  }

  .leftDiamond {
    width: 31px;
    height: 22px;
  }

  .rightDiamond {
    width: 31px;
    height: 22px;
  }

}

@media only screen and (min-width: 1400px) {
  .navbar-links {
    margin-right: 320px;
  }
}